import getPagePropTypes from '@/functions/getPagePropTypes'
import getPostTypeStaticProps from '@/functions/wordpress/postTypes/getPostTypeStaticProps'
import {useRouter} from 'next/router'
import {useEffect} from 'react'

// Define route post type.
const postType = 'page'

export default function Custom404() {
  const router = useRouter()

  useEffect(() => {
    router.replace('/')
  })

  return null
}

/**
 * Get post static props.
 *
 * @author WebDevStudios
 * @return {object} Post props.
 */
export async function getStaticProps() {
  return await getPostTypeStaticProps({slug: '404'}, postType)
}

Custom404.propTypes = {
  ...getPagePropTypes(postType)
}
